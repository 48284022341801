import { template as template_1397c6a253f648bb9e33fec6d942d798 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1397c6a253f648bb9e33fec6d942d798(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
