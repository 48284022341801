import { template as template_e53bbfc8bfcf49348ceef8c0acb7d818 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e53bbfc8bfcf49348ceef8c0acb7d818(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
