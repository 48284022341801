import { template as template_263d23465bdd4c8880f2282109c4ec0a } from "@ember/template-compiler";
const FKText = template_263d23465bdd4c8880f2282109c4ec0a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
